<template>
    <div
        id="frequently-asked-questions"
        class="h-full flex flex-col"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    FAQ
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px]">
                        FAQs
                    </p>
                    <p class="text-navy text-[14px] mb-8">
                        Popular questions and answers
                    </p>
                </div>

                <img
                    class="
						absolute
						-right-[50px]
						-top-[20px]
						w-[140px]
						md:-right-[60px]
					"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>

            <div
                v-for="(faq, index) in faqs"
                :key="index"
                class=""
            >
                <div>
                    <div
                        class="
							bg-white
							rounded-[20px]
							flex flex-col
							justify-between
							mb-2
							w-full
							py-[27px]
							px-[24px]
							shadow-input
							group
							cursor-pointer
							hover:opacity-90
						"
                        @click="faq.isOpen = !faq.isOpen"
                    >
                        <div class="flex items-center justify-between text-left">
                            <p class="text-navy text-[14px]">
                                {{ faq.question }}
                            </p>

                            <i
                                class="
									icon
									text-navy text-[13px]
									transform
									transition
									duration-75
									ease-linear
									icon-chevron-right
								"
                                :class="{ 'rotate-90': faq.isOpen }"
                            />
                        </div>

                        <div
                            v-if="faq.isOpen"
                            class="text-navy text-[14px] text-left"
                        >
                            <p
                                v-if="typeof faq.answer === 'string'"
                                class="text-navy text-[14px] text-left mt-4"
                            >
                                {{ faq.answer }}
                            </p>
                            <template v-else-if="Array.isArray(faq.answer)">
                                <p
                                    v-for="answer in faq.answer"
                                    :key="answer"
                                    class="text-navy text-[14px] text-left mt-4"
                                >
                                    {{ answer }}
                                </p>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    const isOpen = ref(true);
    const faqs = ref([
        {
            question: 'What is Space?',
            answer: [
                `Space lets you reinvent your relationship with your things. Traditional storage options are costly and inconvenient. That's why we created Space. Our easy-to-use platform puts the power back in your hands, so you can focus on the things that matter while we take care of the storage part.`,
                `With a few taps of a button, you can order a box, fill it with the things you want to store, and book a pickup from the comfort of your home. With our partner UPS, we'll collect your box from your doorstep and store it in one of our secure warehouses until you're ready to have it shipped back to you.`,
                `Think simplicity. Think clarity. Think calm. This is your space, reimagined.`
            ],
            isOpen: false
        },
        {
            question: 'How much does it cost?',
            answer: [
                `Each box costs $15 per month with $20 flat rate nation-wide shipping to and from storage. There is a minimum subscription of 4 months per box, and after that we'll take payment on a monthly basis. After 4 months, you can cancel a box at any time. We'll include some helpful packing supplies free of charge like tape strips, packing paper and a vacuum sealed pouch.`,
                `To qualify for the best shipping rates, be sure to keep your boxes under 50 lbs. If you go over this amount you will be charged additional $30 shipping and handling cost per box over the weight limit. But don't worry, the average fully packed box weighs around 35 lbs. Unfortunately we cannot accept any box over 75 lbs.`
            ],
            isOpen: false
        },
        {
            question: 'Are there any hidden extras I should be aware of?',
            answer: "We believe in being completely transparent with prices so that there are no surprises. That's why we have no hidden fees and will always make it clear if extra costs for shipping and handling apply.",
            isOpen: false
        },
        {
            question: 'Where are my things stored and are they safe?',
            answer: `Yes. Our partnership with UPS means that we have access to a network of secure, strategically located warehouses across the country. All of these are staffed around the clock and have 24 hour surveillance. In-person pickups are not allowed, so your box will never be taken by someone else.`,
            isOpen: false
        },
        {
            question: 'How do I get my box back?',
            answer: 'All of your boxes can be managed in the Your Space feed in your Space account. Here, you can select a box and have it shipped back to a location of your choice. Boxes are shipped to you in 3-days or less with most areas qualifying for 2-day shipping with expedited shipping options coming soon.',
            isOpen: false
        },
        {
            question: 'Can I pick up my items from the warehouse?',
            answer: "To keep our customer's items secure, we don't allow in-person pickups from our warehouses.",
            isOpen: false
        },
        {
            question: 'What if my items get lost or damaged?',
            answer: "In the very unlikely event that any of your items get lost or damaged, we will handle all claims in-house so that you don't have to worry about insurance providers. Currently, each box has a protection plan covering all items up to the value of $100. We'll be offering additional protection options in the future.",
            isOpen: false
        },
        {
            question: 'What can I store?',
            answer: "Now is your chance to get creative. Our standard box is spacious in size, so if you're a pro packer, you can fit lots of things in it. To learn more about prohibited items, see our Terms and Conditions.",
            isOpen: false
        },
        {
            question: 'Is there a weight limit per box?',
            answer: 'We provide our flat rate nation-wide shipping rate of $20 for each box to a limit of 50 lbs. If you pack a box that is heavier than this, it will incur additional shipping fees. Unfortunately we cannot accept any box over 75 lbs.',
            isOpen: false
        },
        {
            question: 'How do pickups work?',
            answer: "You can book a pickup by selecting the box you want to send in the Your Space feed in your account. We'll collect it the next business day. Alternatively, you can drop your box at any UPS Access Point or UPS Store.",
            isOpen: false
        },
        {
            question: 'How do deliveries work?',
            answer: 'From the Your Space feed in your account, select the box you want to have delivered. You can then have it shipped to an address of your choice. Boxes are shipped to you in 3-days or less with most areas qualifying for 2-day shipping with expedited shipping options coming soon.',
            isOpen: false
        },
        {
            question: 'Will you offer more box options?',
            answer: 'Absolutely. Right now we have a standard box, and we will look to add more options as time goes on. If you have a suggestion, drop us a line at hello@spacereimagined.io.',
            isOpen: false
        },
        {
            question: 'How can I use Space?',
            answer: [
                `Space is for anyone who needs more space in their lives, or who would like to store items they don't use all of the time. Ideas for things you can store include seasonal clothes, holiday decorations, old files and extra things from your kitchen cabinets.`,
                `But that's not all. If you want to travel or spend time working remotely, you can store your things with Space and order them on-demand to wherever you are. The possibilities to reimagine and reinvent your space are endless.`
            ],
            isOpen: false
        },
        {
            question: 'Can I download Space on my Android device?',
            answer: [
                `You will be able to access your Space account on any device via our web app starting April/May 2022.`,
                `The iOS Space app is currently undergoing a facelift and will be available in the Apple App Store again Summer 2022.`,
                `We're working on making our Android app available in 2023, so watch this space.`
            ],
            isOpen: false
        }
    ]);
</script>
